import axios from "axios";
import React, { Component } from "react";
import AuthService from "../AuthService";
import { Alert, Container, Col, Row, Card, CardBody } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faCalendarAlt,
  faMapMarkerAlt,
  faSpinner,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";

//import Header from './Header.js';
//import EventDetails from './EventDetails.js';
//import InviteResponse from './InviteResponse';
//import Footer from './Footer.js';
import ResponseCard from "./ResponseCard.js";

library.add(faCalendarAlt, faMapMarkerAlt, faSpinner, faExclamationTriangle);

const CardStyle = {
  // margin: "20px",
  // width: "100%",
  // height: "100%"
};

class Response extends Component {
  constructor(props) {
    super(props);
    this.updateInviteWithResponse = this.updateInviteWithResponse.bind(this);
    this.Auth = new AuthService();

    const params = new URLSearchParams(this.props.location.search);
    let new_token = params.get("auth_token");
    this.state = {
      responseCode: null,
      showConfirmation: false,
      changedResponse: false,
      showResponse: "rsvp",
      isFull: false,
      doneLoading: false,
      token: new_token ? new_token : "",
    };
  }

  async updateInvitationResponse(id, response) {
    console.log(`calling update: /api/invites/${id}`);
    console.log(this.state);
    const res = await axios.patch(
      `/api/invites/${id}`,
      {
        response,
      },
      { headers: { Authorization: `Bearer ${this.state.token}` } }
    );
    return res.data;
  }

  async updateInviteWithResponse(response) {
    // called when Yes or No buttons are clicked to send invitation response

    // hide the modal if displayed
    try {
      const invite = await this.updateInvitationResponse(
        this.state.inviteData._id,
        response
      );
      let showResponse =
        parseInt(response) === 0 ? "not_attending" : "attending";
      this.setState({
        showConfirmation: true,
        inviteData: invite,
        showResponse,
      });
      return invite;
    } catch (e) {
      console.error(e.message);
      this.setState({ err: e.message });
      return { err: e.message };
    }
  }

  async getInviteAndEventDetails(id) {
    // call the API to get the invite details and event details
    // console.log(this.state);
    const invite_res = await axios.get(`/api/invites/${id}`, {
      headers: { Authorization: `Bearer ${this.state.token}` },
    });
    const event_res = await axios.get(
      `/api/events/${invite_res.data.eventId}`,
      { headers: { Authorization: `Bearer ${this.state.token}` } }
    );
    const responses_res = await axios.get(
      `/api/invites/event/${invite_res.data.eventId}/responses`,
      { headers: { Authorization: `Bearer ${this.state.token}` } }
    );
    const group_res = await axios.get(`/api/groups/${event_res.data.groupId}`, {
      headers: { Authorization: `Bearer ${this.state.token}` },
    });
    return [
      invite_res.data,
      event_res.data,
      responses_res.data,
      group_res.data,
    ];
  }

  async componentDidMount() {
    // get the Invitation details and event details
    // update the Invitation event with the response and get the Event details
    const { id } = this.props.match.params;
    const urlResponse = this.props.match.params.response;

    let new_state = {};

    // get the invitation details
    let inviteData = {};
    let eventData = {};
    let resData = {};
    let groupData = {};
    try {
      [
        inviteData,
        eventData,
        resData,
        groupData,
      ] = await this.getInviteAndEventDetails(id);
    } catch (e) {
      // couldn't get the invite or event details
      console.error(e.message);
      new_state = {
        responseCode: null,
        showConfirmation: false,
        changedResponse: false,
        showResponse: "error",
        err: e.message,
      };
      this.setState(new_state);
      return;
    }
    try {
      new_state = {
        eventData,
        inviteData,
        resData,
        groupData,
        isFull: resData.attending.length >= groupData.targetParticipants,
      };
      //console.log(new_state);

      if (eventData.hasAlreadyOccurred) {
        // event has already happened, can't accept response
        new_state.showResponse = "expired";
        this.setState(new_state);
        return;
      }

      if (
        (inviteData.response === null || inviteData.response === 0) &&
        resData.attending.length >= groupData.targetParticipants
      ) {
        // event has maxed out on participants, can't accept response
        // TODO - Need to check whether already responded yet or not before deciding what to show
        new_state.showResponse = "full";
        this.setState(new_state);
        return;
      }

      // check the status of the invitation response (new, change, repeat)
      if (urlResponse) {
        new_state.showResponse =
          parseInt(urlResponse) === 0 ? "not_attending" : "attending";
        if (inviteData.response === null) {
          // first response, update invitation in DB and show confirmation to participant
          const newInviteData = await this.updateInvitationResponse(
            id,
            urlResponse
          );
          new_state.inviteData = newInviteData;
          new_state.showConfirmation = true;
        } else if (parseInt(urlResponse) !== parseInt(inviteData.response)) {
          // changed response
          console.log("response HAS changed");
          const newInviteData = await this.updateInvitationResponse(
            id,
            urlResponse
          );
          new_state.inviteData = newInviteData;
          new_state.showConfirmation = true;
          new_state.changedResponse = true;
        } else {
          console.log("response hasn't changed");
          new_state.showConfirmation = false;
          new_state.changedResponse = false;
        }
      } else {
        if (inviteData.response != null) {
          // show the previous response
          //console.log("no response provided, showing current response");
          new_state.showResponse =
            parseInt(inviteData.response) === 0 ? "not_attending" : "attending";
        }
      }

      if (new_state) this.setState(new_state);
    } catch (e) {
      this.setState({ err: e.message });
    }
  }

  render() {
    console.log(this.state);
    let doneLoading =
      this.state.eventData && this.state.inviteData ? true : false;

    //let content = <Spinner color="primary" style={{ width: '3rem', height: '3rem' }} />;
    let content = (
      <div style={{ margin: "auto" }}>
        <Card style={CardStyle}>
          <CardBody>
            <FontAwesomeIcon icon="spinner" size="lg" spin /> Sit tight, looking
            up your invitation ...
          </CardBody>
        </Card>
      </div>
    );
    if (this.state.err) {
      content = (
        <div style={{ margin: "auto" }}>
          <Card style={CardStyle}>
            <CardBody>
              <div align="center">
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  size="4x"
                  color="red"
                />
              </div>
              <br />
              Oops, something went wrong and I couldn't find your invitation.
              Please <a href="mailto:useremeet@gmail.com">email me</a> if you
              continue to experience problems.
            </CardBody>
          </Card>
        </div>
      );
    }
    let alert = <div />;

    if (doneLoading) {
      content = (
        <ResponseCard
          eventData={this.state.eventData}
          inviteData={this.state.inviteData}
          resData={this.state.resData}
          submitResponse={this.updateInviteWithResponse}
          showResponse={this.state.showResponse}
          newResponse={this.props.match.params.response}
          isFull={this.state.isFull}
        />
      );
      if (this.state.showConfirmation) {
        console.log("Showing Confirmation");
        let alertText = "";
        switch (this.state.showResponse) {
          case "attending":
            alertText = "Response recorded.  Glad you can make it.";
            break;
          case "not_attending":
            alertText =
              "Response recorded. Too bad you can't make it this time.";
            break;
          default: {
          }
        }
        if (alertText) {
          alert = <Alert color="info">{alertText}</Alert>;
        }
      }
    }

    return (
      <Container>
        <Row>
          <Col
            style={{ marginTop: "10px" }}
            md={{ size: 8, offset: 2 }}
            lg={{ size: 6, offset: 3 }}
            xl={{ size: 6, offset: 3 }}
          >
            {alert}
          </Col>
        </Row>
        <Row>
          <Col
            md={{ size: 8, offset: 2 }}
            lg={{ size: 6, offset: 3 }}
            xl={{ size: 6, offset: 3 }}
          >
            {content}
            {/*<Header />
						<EventDetails event={this.props.event}/>
						<InviteResponse response={this.props.response}/>
						<Footer />*/}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Response;
