import React from "react";
import { Container } from "reactstrap";
import { Route, Switch } from "react-router-dom";

import HomeContainer from "../components/Home/HomeContainer";
import ParticipantsContainer from "../components/Participants/ParticipantsContainer";

const ContentArea = (props) => {
  return (
    <React.Fragment>
      <Switch>
        <Route exact path="/home">
          <HomeContainer />
        </Route>
        <Route exact path="/participants">
          <ParticipantsContainer />
        </Route>
        <Route exact path="/events">
          Events Coming Soon!
        </Route>
      </Switch>
    </React.Fragment>
  );
};

export default ContentArea;
