import axios from "axios";

import { alertActions } from "./";
import AuthService from "../components/AuthService";
const Auth = new AuthService();

export const GETEVENTRESPONSES_REQUEST = "GETEVENTRESPONSES_REQUEST";
export const GETEVENTRESPONSES_SUCCESS = "GETEVENTRESPONSES_SUCCESS";
export const GETEVENTRESPONSES_FAILURE = "GETEVENTRESPONSES_FAILURE";
export const CREATEINVITE_REQUEST = "CREATEINVITE_REQUEST";
export const CREATEINVITE_SUCCESS = "CREATEINVITE_SUCCESS";
export const CREATEINVITE_FAILURE = "CREATEINVITE_FAILURE";
export const UPDATERESPONSE_REQUEST = "UPDATERESPONSE_REQUEST";
export const UPDATERESPONSE_SUCCESS = "UPDATERESPONSE_SUCCESS";
export const UPDATERESPONSE_FAILURE = "UPDATERESPONSE_FAILURE";

export const responseActions = {
  getEventResponses,
  createInvite,
  updateResponse
};

function getEventResponses(eventId) {
  return async dispatch => {
    dispatch(request(eventId));

    try {
      const responses_res = await axios.get(
        `/api/invites/event/${eventId}/responses`,
        {
          headers: { Authorization: `Bearer ${Auth.getToken()}` }
        }
      );
      const eventResponses = responses_res.data;
      dispatch(success(eventId, eventResponses));
    } catch (e) {
      console.error(e);
      dispatch(failure(e.toString()));
      dispatch(alertActions.error(e.toString()));
    }
  };

  function request(eventId) {
    return { type: GETEVENTRESPONSES_REQUEST, eventId };
  }
  function success(eventId, eventResponses) {
    return { type: GETEVENTRESPONSES_SUCCESS, eventId, eventResponses };
  }
  function failure(error) {
    return { type: GETEVENTRESPONSES_FAILURE, error };
  }
}

function createInvite(person, eventId, groupId, groupName, response) {
  return async dispatch => {
    dispatch(request(person.participantId));

    try {
      const invite_res = await axios.post(
        `/api/invites`,
        {
          participantId: person.participantId,
          participantFirstName: person.firstName,
          participantLastName: person.lastName,
          participantLevel: person.level,
          participantEmail: person.email,
          eventId: eventId,
          groupId: groupId,
          groupName: groupName,
          response: response
        },
        {
          headers: { Authorization: `Bearer ${Auth.getToken()}` }
        }
      );
      const invite = invite_res.data;
      dispatch(success(invite));
      dispatch(getEventResponses(eventId));
    } catch (e) {
      console.error(e);
      dispatch(failure(e.toString()));
      dispatch(alertActions.error(e.toString()));
    }
  };

  function request(participantId) {
    return { type: CREATEINVITE_REQUEST, participantId };
  }
  function success(invite) {
    return { type: CREATEINVITE_SUCCESS, invite };
  }
  function failure(error) {
    return { type: CREATEINVITE_FAILURE, error };
  }
}

function updateResponse(inviteId, response, eventId) {
  return async dispatch => {
    dispatch(request(inviteId, response));

    try {
      const invite_res = await axios.patch(
        `/api/invites/${inviteId}`,
        { response: response },
        {
          headers: { Authorization: `Bearer ${Auth.getToken()}` }
        }
      );
      const invite = invite_res.data;
      dispatch(success(invite));
      dispatch(getEventResponses(eventId));
    } catch (e) {
      console.error(e);
      dispatch(failure(e.toString()));
      dispatch(alertActions.error(e.toString()));
    }
  };

  function request(inviteId, response) {
    return { type: UPDATERESPONSE_REQUEST, inviteId, response };
  }
  function success(invite) {
    return { type: UPDATERESPONSE_SUCCESS, invite };
  }
  function failure(error) {
    return { type: UPDATERESPONSE_FAILURE, error };
  }
}
