import { LOGIN_SUCCESS, GETPROFILE_SUCCESS, LOGOUT } from "../actions";

function userReducer(user = { user: {}, isAuthenticated: false }, action) {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        user: action.user,
        isAuthenticated: true
      };
    case GETPROFILE_SUCCESS:
      return {
        user: action.user,
        isAuthenticated: true
      };

    case LOGOUT:
      return {
        user: {},
        isAuthenticated: false
      };
    default:
      return user;
  }
}

export default userReducer;
