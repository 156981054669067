import React, { Component } from "react";
import { Tooltip } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/fontawesome-free-regular";
import { faCheckCircle } from "@fortawesome/fontawesome-free-solid";
import { connect } from "react-redux";

import { responseActions } from "../../actions";

const checkIconOff = ["far", "check-circle"];
const timesIconOff = ["far", "times-circle"];
const checkIconOn = "check-circle";
const timesIconOn = "times-circle";
const checkTooltipAdd = "Attending";
const tooltipRemove = "No Response";
const timesTooltipAdd = "Not Attending";

class EventParticipantActions extends Component {
  constructor(props) {
    super(props);
    this.submitInviteResponse = this.submitInviteResponse.bind(this);
    this.toggleCheckHover = this.toggleCheckHover.bind(this);
    this.toggleTimesHover = this.toggleTimesHover.bind(this);
    this.state = {
      checkIcon: false,
      timesIcon: false,
      checkIconHover: false,
      timesIconHover: false,
      checkTooltipOpen: false,
      timesTooltipOpen: false,
    };
  }

  componentDidMount() {
    let response = this.props.person.response;

    switch (response) {
      case 1:
        // check icon is selected and disabled
        // checkIcon = checkIconOn;
        this.setState({ checkIcon: true });
        break;
      case 0:
        // times icon is selected and disabled
        // timesIcon = timesIconOn;
        this.setState({ timesIcon: true });
        break;
    }
  }

  async submitInviteResponse(iconType) {
    // if there's an invitation ID, update the invite
    // if there's not an invite ID, create and set the response on the invite
    const person = this.props.person;
    const eventId = this.props.nextEventId;
    const groupId = this.props.events[eventId].groupId;
    //const group = getGroupFromGroups(nextEvent.groupId, this.props.groups);
    const group = this.props.groups.find((group) => {
      return group._id === groupId;
    });
    const groupName = group.name;

    let response = undefined;
    // check whether icon is already checked to toggle status.
    // NOTE: icon will be opposite because of hover
    if (iconType == "check") {
      this.state.checkIcon ? (response = 1) : (response = null);
    } else if (iconType == "times") {
      this.state.timesIcon ? (response = 0) : (response = null);
    } else {
      console.error("Invalid icon type submitted");
      return;
    }

    if ("invitationId" in person && person.invitationId) {
      //have an invitation, update the response
      this.props.updateResponse(person.invitationId, response, eventId);
    } else {
      //no invitation, need to create
      this.props.createInvite(person, eventId, groupId, groupName, response);
    }
    // this.setState({ toggleUpdate: !this.state.toggleUpdate });
  }

  toggleCheckHover() {
    this.setState((prevState) => ({
      checkIcon: !prevState.checkIcon,
      checkTooltipOpen: !prevState.checkTooltipOpen,
    }));
  }

  toggleTimesHover() {
    this.setState((prevState) => ({
      timesIcon: !prevState.timesIcon,
      timesTooltipOpen: !prevState.timesTooltipOpen,
    }));
  }

  render() {
    const { person, nextEventId, events, groups } = this.props;
    const groupId = events[nextEventId].groupId;
    const group = groups.find((group) => {
      return group._id === groupId;
    });
    const groupName = group.name;

    return (
      <div>
        <FontAwesomeIcon
          icon={this.state.checkIcon ? checkIconOn : checkIconOff}
          color="green"
          size="lg"
          id={`check-${person.participantId}`}
          onClick={() => this.submitInviteResponse("check")}
        />
        <Tooltip
          placement="top"
          isOpen={this.state.checkTooltipOpen}
          toggle={this.toggleCheckHover}
          target={`check-${person.participantId}`}
        >
          {this.state.checkIcon ? checkTooltipAdd : tooltipRemove}
        </Tooltip>{" "}
        <FontAwesomeIcon
          icon={this.state.timesIcon ? timesIconOn : timesIconOff}
          color="#DC3545"
          size="lg"
          id={`times-${person.participantId}`}
          onClick={() => this.submitInviteResponse("times")}
        />
        <Tooltip
          placement="top"
          isOpen={this.state.timesTooltipOpen}
          toggle={this.toggleTimesHover}
          target={`times-${person.participantId}`}
        >
          {this.state.timesIcon ? timesTooltipAdd : tooltipRemove}
        </Tooltip>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    groups: state.groups,
    events: state.events,
  };
};

const mapDispatchToProps = {
  createInvite: responseActions.createInvite,
  updateResponse: responseActions.updateResponse,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventParticipantActions);
