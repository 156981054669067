import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Container } from "reactstrap";

import Home from "./Home";
import Spinner from "../Spinner";

class HomeContainer extends Component {
  renderHome() {
    // TODO: Assumes a single group already exists.
    // Need to support 0 or 2+ groups in the future

    //Wait for groups to load
    if (!this.props.groups || this.props.groups.length == 0) {
      return <Spinner />;
    }

    //Check for a next event and responses.  If none show No Next Event
    if (!this.props.groups[0].nextEvent) {
      return <Home group={group} />;
    }

    const nextEventId = this.props.groups[0].nextEvent;
    const groupId = this.props.events[nextEventId].groupId;
    const group = this.props.groups.find((g) => g._id === groupId);
    const eventDetails = this.props.events[nextEventId];
    const nextEventResponses = this.props.eventResponses[nextEventId];
    return (
      <Home
        group={group}
        eventDetails={eventDetails}
        nextEventResponses={nextEventResponses}
      />
    );
  }

  render() {
    return (
      <Container>
        <Helmet>
          <title>reMeet Home</title>
          <meta name="description" content="Home" />
        </Helmet>
        {this.renderHome()}
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    groups: state.groups,
    events: state.events,
    eventResponses: state.responses,
  };
};

// const mapDispatchToProps = {
//   getGroupsWithEvents: groupActions.getGroupsWithEvents,
// };

export default connect(mapStateToProps)(HomeContainer);
