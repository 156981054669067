import React, { Component } from "react";
import { isEmpty } from "lodash";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Container } from "reactstrap";
import { Redirect } from "react-router-dom";

import { participantActions } from "../../actions";
import Participants from "./Participants";
import Spinner from "../Spinner";

class ParticipantsContainer extends Component {
  render() {
    //Wait for groups to load
    if (this.props.app.selectedGroup && isEmpty(this.props.participants)) {
      this.props.getGroupParticipants(this.props.app.selectedGroup);
    }
    if (
      isEmpty(this.props.groups) ||
      isEmpty(this.props.participants) ||
      !this.props.app.selectedGroup
    ) {
      return <Spinner />;
    }
    // if (!this.props.app.selectedGroup) {
    //   return <Redirect to="/home" />;
    // }
    const groupId = this.props.groups[0]._id;
    const group = this.props.groups.find((g) => g._id === groupId);

    return (
      <Container>
        <Helmet>
          <title>Members</title>
          <meta name="description" content="Group Members" />
        </Helmet>
        <Participants group={group} participants={this.props.participants} />
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    groups: state.groups,
    participants: state.participants,
    app: state.app,
  };
};

const mapDispatchToProps = {
  getGroupParticipants: participantActions.getGroupParticipants,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ParticipantsContainer);
