import React, { Component } from "react";
import { Button, Form, FormGroup, Input, Label, Alert } from "reactstrap";

import "./Login.css";

class Login extends Component {
  render() {
    const { alert, email, password } = this.props;
    return (
      <div className="Login">
        <Form onSubmit={e => this.props.handleSubmitForm(e)}>
          {alert.message && <Alert color={alert.type}>{alert.message}</Alert>}
          <FormGroup>
            <Label>Email</Label>
            <Input
              autoFocus
              type="email"
              name="email"
              placeholder="myemail@email.com"
              value={email}
              onChange={e => {
                this.props.handleChange(e);
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label>Password</Label>
            <Input
              type="password"
              name="password"
              placeholder="********"
              value={password}
              onChange={e => {
                this.props.handleChange(e);
              }}
            />
          </FormGroup>
          <Button block disabled={!this.props.validateForm()} type="submit">
            Login
          </Button>
        </Form>
      </div>
    );
  }
}

export default Login;
