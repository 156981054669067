import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faMapMarkerAlt,
  faUserCircle
} from "@fortawesome/free-solid-svg-icons";
import {
  Table,
  Col,
  Row,
  Button,
  Card,
  CardTitle,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import Moment from "react-moment";
import "moment-timezone";

//import { Card, CardImg, CardText, CardBody,
//  CardTitle, CardSubtitle, Button } from 'reactstrap';

//import ResponseForm from './ResponseForm';

const Styles = {
  cardStyleNormal: {
    marginTop: "10px"
  },
  cardStyleExpired: {
    marginTop: "10px",
    backgroundColor: "#333",
    borderColor: "#333"
  },
  rowStyle: {
    fontSize: 20
    // color: "#404040"
  },
  rowErrorStyle: {
    fontSize: 20,
    color: "gray",
    fontStyle: "italic",
    textAlign: "center"
  },
  responseMessage: {
    fontWeight: "bold",
    textAlign: "center",
    fontSize: 18
  },
  responseText: {
    fontWeight: "bold",
    textAlign: "center",
    fontSize: 25
  }
};

class ResponseCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      newResponse: null
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle(newResponse) {
    this.setState({
      showModal: !this.state.showModal,
      newResponse
    });
  }

  submitResponseAndToggle(response) {
    this.setState({
      showModal: !this.state.showModal,
      newResponse: null
    });
    this.props.submitResponse(response);
  }

  getModalBody() {
    if (this.props.isFull && this.state.newResponse == 1) {
      return (
        <React.Fragment>
          This event has maxed out at {this.props.resData.attending.length}{" "}
          people. If you now want to attend, contact the organizer to see if any
          spots free up.
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          No worries, plans change. Click confirm to change your response.
        </React.Fragment>
      );
    }
  }

  getResponseText(response) {
    let responseMessage;
    switch (response) {
      case "not_attending":
        //not attending
        responseMessage = (
          <React.Fragment>
            <Row>
              <Col>
                <p className="text-danger" style={Styles.responseText}>
                  Not Attending
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p style={Styles.responseMessage}>
                  Sorry you can't make it. You'll be missed!
                </p>
              </Col>
            </Row>
            <Row>
              <Col style={{ textAlign: "center" }}>
                <Button onClick={() => this.toggle(1)} color="link">
                  Able to make it after all?
                </Button>
              </Col>
            </Row>
          </React.Fragment>
        );
        break;
      case "attending":
        responseMessage = (
          <React.Fragment>
            <Row>
              <Col>
                <p className="text-success" style={Styles.responseText}>
                  Attending
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p style={Styles.responseMessage}>
                  Glad you can make it. See you there!
                </p>
              </Col>
            </Row>
            <Row>
              <Col style={{ textAlign: "center" }}>
                <Button onClick={() => this.toggle(0)} color="link">
                  Can't make it after all?
                </Button>
              </Col>
            </Row>
          </React.Fragment>
        );
        break;
      case "expired":
        responseMessage = (
          <React.Fragment>
            <Row>
              <Col>
                <p className="text-primary" style={Styles.responseText}>
                  Event Expired
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p style={Styles.responseMessage}>
                  You snooze you lose! This event has already happened.
                </p>
              </Col>
            </Row>
          </React.Fragment>
        );
        break;
      case "full":
        responseMessage = (
          <React.Fragment>
            <Row>
              <Col>
                <p className="text-primary" style={Styles.responseText}>
                  Event is full
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p style={Styles.responseMessage}>
                  This event has maxed out at{" "}
                  {this.props.resData.attending.length} people. Contact the
                  organizer to see if any spots free up.
                </p>
              </Col>
            </Row>
          </React.Fragment>
        );
        break;
      default:
        responseMessage = (
          <React.Fragment>
            <Row>
              <Col>
                <p style={Styles.responseMessage}>Are you able to make it?</p>
              </Col>
            </Row>
            <Row>
              <Col style={{ textAlign: "center" }}>
                <Button
                  onClick={() => this.props.submitResponse(1)}
                  style={{ width: "100px" }}
                  color="success"
                >
                  Yes
                </Button>
              </Col>
              <Col style={{ textAlign: "center" }}>
                <Button
                  onClick={() => this.props.submitResponse(0)}
                  style={{ width: "100px" }}
                  color="danger"
                >
                  No
                </Button>
              </Col>
            </Row>
          </React.Fragment>
        );
    }

    return responseMessage;
  }
  showEventDetails(eventData) {
    if (eventData.eventTime && eventData.location) {
      return (
        <React.Fragment>
          <tr>
            <td>
              <FontAwesomeIcon size="2x" icon={faCalendarAlt} />
            </td>
            <td style={Styles.rowStyle}>
              <Moment format="LL">{eventData.eventTime}</Moment> @{" "}
              <Moment format="LT">{eventData.eventTime}</Moment>
            </td>
          </tr>
          <tr>
            <td>
              <FontAwesomeIcon size="2x" icon={faMapMarkerAlt} />
            </td>
            <td style={Styles.rowStyle}>{eventData.location}</td>
          </tr>
        </React.Fragment>
      );
    } else {
      console.error("can't display event details!");
      return (
        <tr>
          <td colSpan="2" style={Styles.rowErrorStyle}>
            Oops! Can't find event details.
          </td>
        </tr>
      );
    }
  }

  render() {
    const { inviteData, eventData, showResponse } = this.props;

    return (
      <div>
        <Card
          inverse={showResponse === "expired"}
          style={
            showResponse === "expired"
              ? Styles.cardStyleExpired
              : Styles.cardStyleNormal
          }
        >
          <CardBody>
            <CardTitle style={{ fontSize: 30, marginBottom: 20 }}>
              {inviteData.groupName}
            </CardTitle>
            <Table borderless size="sm">
              <tbody>
                <tr>
                  <td>
                    <FontAwesomeIcon size="2x" icon={faUserCircle} />
                  </td>
                  <td style={Styles.rowStyle}>
                    <span style={{ fontSize: 20 }}>
                      {inviteData.participantFirstName}{" "}
                      {inviteData.participantLastName}
                      {/*<CardLink className="text-muted" style={{fontSize: 16}} href="#">  (not {inviteData.participantFirstName}?)</CardLink>*/}
                    </span>
                  </td>
                </tr>
                {this.showEventDetails(eventData)}
              </tbody>
            </Table>
            {this.getResponseText(this.props.showResponse)}
          </CardBody>
        </Card>
        <Modal
          isOpen={this.state.showModal}
          toggle={() => this.toggle(null)}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggle}>Change your response?</ModalHeader>
          <ModalBody>{this.getModalBody()}</ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() =>
                this.submitResponseAndToggle(this.state.newResponse)
              }
              disabled={this.props.isFull && this.state.newResponse == 1}
            >
              Confirm
            </Button>{" "}
            <Button color="secondary" onClick={() => this.toggle(null)}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default ResponseCard;
