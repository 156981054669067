import React, { Component } from "react";

import ParticipantList from "../ParticipantList/ParticipantList";
import settings from "../../config/settings";

class EventResponses extends Component {
  render() {
    const { eventDetails, nextEventResponses } = this.props;
    return (
      <React.Fragment>
        {Object.keys(settings.LIST_TEXT).map((key, index) => {
          return (
            <ParticipantList
              key={key}
              listType="event"
              participants={nextEventResponses[key]}
              titleText={settings.LIST_TEXT[key]}
              nextEventId={eventDetails._id}
            />
          );
        })}
      </React.Fragment>
    );
  }
}

export default EventResponses;
