import eventsReducer from "./eventsReducer";
import groupsReducer from "./groupsReducer";
import userReducer from "./userReducer";
import participantsReducer from "./participantsReducer";
import responsesReducer from "./responsesReducer";
import alertReducer from "./alertReducer";
import appReducer from "./appReducer";
import { combineReducers } from "redux";
import { LOGOUT } from "../actions";

const allReducers = combineReducers({
  auth: userReducer,
  events: eventsReducer,
  groups: groupsReducer,
  participants: participantsReducer,
  responses: responsesReducer,
  alert: alertReducer,
  app: appReducer,
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    state = undefined;
  }

  return allReducers(state, action);
};

export default rootReducer;
