import React from "react";
import { Container, Col, Row } from "reactstrap";
import { Link } from "react-router-dom";

import EventResponses from "../EventResponses/EventResponses";
import EventDetails from "../EventDetails/EventDetails";
import EventAttendanceSummary from "../EventAttendanceSummary/EventAttendanceSummary";
import "./NextEvent.css";

const NextEvent = ({
  targetParticipants,
  eventDetails,
  nextEventResponses,
}) => {
  return (
    <React.Fragment>
      <Row>
        <Col sm={{ size: 8, offset: 2 }}>
          <EventAttendanceSummary
            targetParticipants={targetParticipants}
            nextEventResponses={nextEventResponses}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={{ size: 6, offset: 3 }}>
          <EventDetails
            eventTime={eventDetails.eventTime}
            location={eventDetails.location}
            eventDurationMins={eventDetails.eventDurationMins}
          />
        </Col>
      </Row>
      <Row>
        <Col className="px-0 px-sm-3" sm={{ size: 8, offset: 2 }}>
          <EventResponses
            eventDetails={eventDetails}
            nextEventResponses={nextEventResponses}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default NextEvent;
