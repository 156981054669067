import AuthService from "../components/AuthService";
import { alertActions, responseActions, eventActions, appActions } from "./";
import axios from "axios";

const Auth = new AuthService();

export const GETGROUPS_REQUEST = "GETGROUPS_REQUEST";
export const GETGROUPS_SUCCESS = "GETGROUPS_SUCCESS";
export const GETGROUPS_FAILURE = "GETGROUPS_FAILURE";

export const groupActions = {
  getGroupsWithEvents,
};

function getGroupsWithEvents(userId) {
  return async (dispatch, getState) => {
    if (!getState().auth.user) {
      console.error("Can't get groups, user not found.");
      dispatch(failure("No user object found"));
      dispatch(alertActions.error("No logged in user found"));
      return;
    }
    const { user } = getState().auth;
    const { selectedGroup } = getState().app;
    dispatch(request(user._id));

    try {
      const group_res = await axios.get(
        `/api/groups?organizerId=${user._id}&includeFutureEvents=true`,
        {
          headers: { Authorization: `Bearer ${Auth.getToken()}` },
        }
      );
      const groups = group_res.data;
      if (!selectedGroup) {
        if (groups.length > 0) {
          dispatch(appActions.setSelectedGroup(groups[0]._id));
        }
      }
      let promises = [];
      for (let index = 0; index < groups.length; index++) {
        const group = groups[index];
        if (group.nextEvent) {
          promises.push(
            await dispatch(eventActions.getEventDetails(group.nextEvent))
          );
          promises.push(
            await dispatch(responseActions.getEventResponses(group.nextEvent))
          );
        }
      }
      promises.push(await dispatch(success(groups)));
      await Promise.all(promises);
    } catch (e) {
      console.error(e);
      dispatch(failure(e.toString()));
      dispatch(alertActions.error(e.toString()));
    }
  };

  function request(user) {
    return { type: GETGROUPS_REQUEST, user };
  }
  function success(groups) {
    return { type: GETGROUPS_SUCCESS, groups };
  }
  function failure(error) {
    return { type: GETGROUPS_FAILURE, error };
  }
}
