import { ADDEVENT_SUCCESS } from "../actions";

function eventsReducer(events = {}, action) {
  switch (action.type) {
    case ADDEVENT_SUCCESS: {
      const { eventId, eventDetails } = action;
      let newEvents = Object.assign({}, events);
      newEvents[eventId] = eventDetails;
      return newEvents;
    }

    default:
      return events;
  }
}

export default eventsReducer;
