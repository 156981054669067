import { GETEVENTRESPONSES_SUCCESS } from "../actions";

function responsesReducer(responses = {}, action) {
  switch (action.type) {
    case GETEVENTRESPONSES_SUCCESS: {
      const { eventId, eventResponses } = action;
      let newResponses = Object.assign({}, responses);
      newResponses[eventId] = eventResponses;
      return newResponses;
    }

    default:
      return responses;
  }
}

export default responsesReducer;
