import React from "react";
import Moment from "react-moment";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faMapMarkerAlt,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import { Table } from "reactstrap";

const EventDetails = ({ eventTime, location, eventDurationMins }) => {
  let endTime = null;
  if (eventDurationMins) {
    endTime = moment(eventTime).add(eventDurationMins, "m");
  }
  return (
    <Table borderless size="sm">
      <tbody>
        <tr>
          <td>
            <FontAwesomeIcon size="1x" icon={faCalendarAlt} />
          </td>
          <td className="rowStyle">
            <Moment format="LL">{eventTime}</Moment>
          </td>
        </tr>
        <tr>
          <td>
            <FontAwesomeIcon size="1x" icon={faClock} />
          </td>
          <td className="rowStyle">
            <Moment format="LT">{eventTime}</Moment>{" "}
            {eventDurationMins ? (
              <React.Fragment>
                - <Moment format="LT">{endTime}</Moment>
              </React.Fragment>
            ) : (
              ""
            )}
          </td>
        </tr>
        <tr>
          <td>
            <FontAwesomeIcon size="1x" icon={faMapMarkerAlt} />
          </td>
          <td className="rowStyle">{location}</td>
        </tr>
      </tbody>
    </Table>
  );
};

export default EventDetails;
