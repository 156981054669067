import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isEmpty } from "lodash";

export const groupParticipantsByLevel = (participants, groupText) => {
  //takes in participant list and returns array of participants by group level + inactives
  // expects groupText length to correspond to the number of participant levels in participants
  // also normalizes _id to participantId so it matches response values
  if (!Array.isArray(participants) || isEmpty(participants)) {
    return [];
  }

  let groupedParticipants = [];
  groupedParticipants = groupText.map((levelName, index) => {
    let newGroup = participants.filter(
      (person) => person.groupLevel === index && person.active === true
    );
    newGroup.forEach((person) => (person.participantId = person._id));
    return newGroup;
  });

  // groupedParticipants.push(
  //   participants.filter(
  //     (person) => person.groupLevel === 0 && person.active === true
  //   )
  // );
  // groupedParticipants.push(
  //   participants.filter(
  //     (person) => person.groupLevel === 1 && person.active === true
  //   )
  // );

  let inactive = [];
  inactive = participants.filter((person) => person.active === false);
  inactive.forEach((person) => (person.participantId = person._id));
  groupedParticipants.push(inactive);

  return groupedParticipants;
};
