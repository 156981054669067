import React from "react";
import { Card, CardBody, CardHeader, Collapse } from "reactstrap";

import ParticipantListItem from "./ParticipantListItem";
import EventParticipantModal from "./EventParticipantModal";
import "./ParticipantList.css";

class ParticipantList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      isOpen: this.props.listType === "event" ? false : true,
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.toggleCard = this.toggleCard.bind(this);
  }

  toggleModal() {
    this.setState({
      showModal: !this.state.showModal,
    });
  }
  toggleCard(e) {
    let event = e.target.dataset.event;
    this.setState({ isOpen: !this.state.isOpen });
  }

  render() {
    const { listType, participants, titleText, nextEventId } = this.props;

    if (!listType || !participants) {
      return <div></div>;
    }

    return (
      <React.Fragment>
        <Card>
          <CardHeader onClick={this.toggleCard}>
            <h4>
              {titleText} ({participants.length})
            </h4>
          </CardHeader>
          <Collapse isOpen={this.state.isOpen}>
            {participants.length ? (
              participants.map((person) => {
                return (
                  <ParticipantListItem
                    key={person.participantId}
                    person={person}
                    listType={listType}
                    nextEventId={nextEventId}
                  />
                );
              })
            ) : (
              <ParticipantListItem listType="empty" />
            )}
          </Collapse>
        </Card>
        <EventParticipantModal
          open={this.state.showModal}
          toggle={this.toggle}
        />
      </React.Fragment>
    );
  }
}

export default ParticipantList;
