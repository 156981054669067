import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
  NavbarText,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/fontawesome-free-regular";

import "./Header.css";

export default class Header extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  render() {
    const { user, logout, activeGroupId, activeGroupName } = this.props;
    let groupDisplayName = activeGroupName;

    if (!activeGroupId || !activeGroupName) {
      groupDisplayName = "No Group";
    }

    return (
      <div>
        <Navbar
          color="white"
          light
          expand="xs"
          height="75px"
          className="rounded-top"
        >
          <Link to="/" className="navbar-brand">
            reMeet
          </Link>
          <NavbarText>{activeGroupName}</NavbarText>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  <FontAwesomeIcon icon={["far", "user-circle"]} />
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem header>
                    {user.firstName} {user.lastName}
                  </DropdownItem>
                  <DropdownItem onClick={logout}>Logout</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}
