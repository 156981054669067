import {
  GETGROUPS_SUCCESS,
  GETEVENTRESPONSES_SUCCESS,
  UPDATENEXTEVENT
} from "../actions";

function groupsReducer(groups = [], action) {
  switch (action.type) {
    case GETGROUPS_SUCCESS:
      return action.groups;

    case GETEVENTRESPONSES_SUCCESS: {
      const { groupIndex, eventId, responses } = action;
      return Object.assign([], groups).map((group, index) => {
        if (index === groupIndex) {
          // need to update the event for the group to include responses
          if (!(eventId in group.events)) {
            group.events[eventId] = {};
          }
          group.events[eventId].responses = responses;
          return group;
        }
        // just return the group if not adding responses
        return group;
      });
    }

    case UPDATENEXTEVENT: {
      const { groupId, nextEventId } = action;
      return Object.assign([], groups).map((group, index) => {
        if (group._id === groupId) {
          //this is the group that needs to update the nextEventId
          group.nextEvent = nextEventId;
          return group;
        }
        return group;
      });
    }

    default:
      return groups;
  }
}

export default groupsReducer;
