import axios from "axios";

import { alertActions, groupActions } from "./";
import AuthService from "../components/AuthService";
const Auth = new AuthService();

export const ADDEVENT_REQUEST = "ADDEVENT_REQUEST";
export const ADDEVENT_SUCCESS = "ADDEVENT_SUCCESS";
export const ADDEVENT_FAILURE = "ADDEVENT_FAILURE";
export const GETNEXTEVENT_REQUEST = "GETNEXTEVENT_REQUEST";
export const GETNEXTEVENT_SUCCESS = "GETNEXTEVENT_SUCCESS";
export const GETNEXTEVENT_FAILURE = "GETNEXTEVENT_FAILURE";
export const UPDATENEXTEVENT = "UPDATENEXTEVENT";

export const eventActions = {
  getEventDetails,
  getNextEvent
};

function getNextEvent(groupId) {
  return async dispatch => {
    dispatch(request(groupId));

    try {
      const responses_res = await axios.get(
        `/api/events/groupId=${groupId}&nextEvent=true`,
        {
          headers: { Authorization: `Bearer ${Auth.getToken()}` }
        }
      );
      const nextEventId = responses_res.data._id;
      dispatch(success(nextEventId, responses_res.data));
      dispatch(updateGroup(groupId, nextEventId));
    } catch (e) {
      console.error(e);
      dispatch(failure(e.toString()));
      dispatch(alertActions.error(e.toString()));
    }
  };

  function request(groupId) {
    return { type: GETNEXTEVENT_REQUEST, groupId };
  }
  function success(eventId, eventDetails) {
    return { type: GETNEXTEVENT_SUCCESS, eventId, eventDetails };
  }
  function updateGroup(groupId, nextEventId) {
    return { type: UPDATENEXTEVENT, groupId, nextEventId };
  }
  function failure(error) {
    return { type: GETNEXTEVENT_FAILURE, error };
  }
}

function getEventDetails(eventId) {
  return async dispatch => {
    dispatch(request(eventId));

    try {
      const eventDetails_res = await axios.get(`/api/events/${eventId}`, {
        headers: { Authorization: `Bearer ${Auth.getToken()}` }
      });
      dispatch(success(eventId, eventDetails_res.data));
    } catch (e) {
      console.error(e);
      dispatch(failure(e.toString()));
      dispatch(alertActions.error(e.toString()));
    }
  };

  function request(eventId) {
    return { type: ADDEVENT_REQUEST, eventId };
  }
  function success(eventId, eventDetails) {
    return { type: ADDEVENT_SUCCESS, eventId, eventDetails };
  }
  function failure(error) {
    return { type: ADDEVENT_FAILURE, error };
  }
}
