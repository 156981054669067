import React from "react";
import { Col, Row, ListGroupItem } from "reactstrap";

import EventParticipantActions from "./EventParticipantActions";
import ParticipantActions from "./ParticipantActions";

const ParticipantListItem = ({ person, listType, nextEventId }) => {
  // listTypes:
  //   "member" = (default) includes ParticipantActions on each row
  //   "event" = includes EventParticipantActions on each row
  //   "empty" = no data for the row so just show Empty
  if (listType === "empty") {
    return (
      <ListGroupItem className="list-none">
        <Row>
          <Col>Empty</Col>
        </Row>
      </ListGroupItem>
    );
  }
  return (
    <ListGroupItem className="listitem" key={person.participantId}>
      <Row>
        <Col xs="8" className="d-inline-flex align-items-center d-sm-block">
          <div className="participant-name h5 ">
            {person.firstName} {person.lastName}
          </div>
          {listType === "member" && person.email ? (
            <div className="text-muted d-none d-sm-block">{person.email}</div>
          ) : (
            ""
          )}
        </Col>
        <Col xs="4" className="text-right pr-0 pr-sm-1">
          {listType === "event" ? (
            <EventParticipantActions
              person={person}
              nextEventId={nextEventId}
            />
          ) : (
            <ParticipantActions person={person} />
          )}
        </Col>
      </Row>
    </ListGroupItem>
  );
};

export default ParticipantListItem;
