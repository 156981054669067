import axios from "axios";

import { alertActions } from "./";
import AuthService from "../components/AuthService";
const Auth = new AuthService();

export const GETPARTICIPANTS_REQUEST = "GETPARTICIPANTS_REQUEST";
export const GETPARTICIPANTS_SUCCESS = "GETPARTICIPANTS_SUCCESS";
export const GETPARTICIPANTS_FAILURE = "GETPARTICIPANTS_FAILURE";
export const DELETEPARTICIPANT_REQUEST = "DELETEPARTICIPANT_REQUEST";
export const DELETEPARTICIPANT_SUCCESS = "DELETEPARTICIPANT_SUCCESS";
export const DELETEPARTICIPANT_FAILURE = "DELETEPARTICIPANT_FAILURE";
export const CHANGEPARTICIPANTSTATUS_REQUEST =
  "CHANGEPARTICIPANTSTATUS_REQUEST";
export const CHANGEPARTICIPANTSTATUS_SUCCESS =
  "CHANGEPARTICIPANTSTATUS_SUCCESS";
export const CHANGEPARTICIPANTSTATUS_FAILURE =
  "CHANGEPARTICIPANTSTATUS_FAILURE";
export const UPDATEPARTICIPANT_REQUEST = "UPDATEPARTICIPANT_REQUEST";
export const UPDATEPARTICIPANT_SUCCESS = "UPDATEPARTICIPANT_SUCCESS";
export const UPDATEPARTICIPANT_FAILURE = "UPDATEPARTICIPANT_FAILURE";

export const participantActions = {
  getGroupParticipants,
  deleteParticipant,
  changeParticipantStatus,
  updateParticipant,
};

function getGroupParticipants(groupId) {
  return async (dispatch) => {
    dispatch(request(groupId));

    try {
      const request_res = await axios.get(
        `/api/participants?groupId=${groupId}`,
        {
          headers: { Authorization: `Bearer ${Auth.getToken()}` },
        }
      );
      dispatch(success(groupId, request_res.data));
    } catch (e) {
      console.error(e);
      dispatch(failure(e.toString()));
      dispatch(alertActions.error(e.toString()));
    }
  };

  function request(groupId) {
    return { type: GETPARTICIPANTS_REQUEST, groupId };
  }
  function success(groupId, participants) {
    return { type: GETPARTICIPANTS_SUCCESS, groupId, participants };
  }
  function failure(error) {
    return { type: GETPARTICIPANTS_FAILURE, error };
  }
}

function deleteParticipant(participantId, groupId) {
  return async (dispatch) => {
    dispatch(request(participantId));

    try {
      const request_res = await axios.delete(
        `/api/participants/${participantId}`,
        {
          headers: { Authorization: `Bearer ${Auth.getToken()}` },
        }
      );
      dispatch(success(participantId, request_res.data));
      dispatch(getGroupParticipants(groupId));
    } catch (e) {
      console.error(e);
      dispatch(failure(e.toString()));
      dispatch(alertActions.error(e.toString()));
    }
  };

  function request(participantId) {
    return { type: DELETEPARTICIPANT_REQUEST, participantId };
  }
  function success(participantId, response) {
    return { type: DELETEPARTICIPANT_SUCCESS, participantId, response };
  }
  function failure(error) {
    return { type: DELETEPARTICIPANT_FAILURE, error };
  }
}

function changeParticipantStatus(participantId, newStatus) {
  return async (dispatch) => {
    dispatch(request(participantId));

    if (typeof newStatus != "boolean") {
      dispatch(failure("changeParticipantStatus: expected boolean newStatus"));
      return;
    }
    try {
      const request_res = await axios.patch(
        `/api/participants/${participantId}`,
        { active: newStatus },
        {
          headers: { Authorization: `Bearer ${Auth.getToken()}` },
        }
      );
      dispatch(success(participantId, request_res.data));
      dispatch(getGroupParticipants(request_res.data.groupId));
    } catch (e) {
      console.error(e);
      dispatch(failure(e.toString()));
      dispatch(alertActions.error(e.toString()));
    }
  };

  function request(participantId) {
    return { type: CHANGEPARTICIPANTSTATUS_REQUEST, participantId };
  }
  function success(participantId, response) {
    return { type: CHANGEPARTICIPANTSTATUS_SUCCESS, participantId, response };
  }
  function failure(error) {
    return { type: CHANGEPARTICIPANTSTATUS_FAILURE, error };
  }
}

function updateParticipant(newValues, participantId, groupId) {
  return async (dispatch) => {
    dispatch(request(participantId));

    try {
      console.log(
        `firstName=${newValues.firstName}, lastName=${newValues.lastName}, email=${newValues.email}, active=${newValues.active}, groupLevel=${newValues.groupLevel}, groupId=${groupId}, participantId=${participantId}`
      );
      const request_res = await axios.patch(
        `/api/participants/${participantId}`,
        {
          firstName: newValues.firstName,
          lastName: newValues.lastName,
          email: newValues.email,
          active: newValues.active,
          groupLevel: newValues.groupLevel,
        },
        {
          headers: { Authorization: `Bearer ${Auth.getToken()}` },
        }
      );
      console.log(request_res.data);
      dispatch(success(participantId, request_res.data));
      dispatch(getGroupParticipants(groupId));
    } catch (e) {
      console.error(e);
      dispatch(failure(e.toString()));
      dispatch(alertActions.error(e.toString()));
    }
  };

  function request(participantId) {
    return { type: UPDATEPARTICIPANT_REQUEST, participantId };
  }
  function success(participantId, response) {
    return { type: UPDATEPARTICIPANT_SUCCESS, participantId, response };
  }
  function failure(error) {
    return { type: UPDATEPARTICIPANT_FAILURE, error };
  }
}
