import AuthService from "../AuthService";
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

import { userActions, alertActions } from "../../actions";
import Login from "./Login";
import Spinner from "../Spinner";

class LoginContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      checkedSession: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmitForm = this.handleSubmitForm.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.Auth = new AuthService();

    this.props.history.listen((location, action) => {
      // clear alert on location change
      this.props.clearAlerts();
    });
  }

  componentDidMount() {
    //check if already authenticated
    this.checkSession();
  }

  async checkSession() {
    if (this.Auth.loggedIn()) {
      await this.props.getProfile();
      if (this.props.isAuthenticated) {
        //user profile is found and isAuthenticated=true, redirect user
        this.props.history.push(this.getRedirectPath());
      }
    }
    this.setState({ checkedSession: true });
  }

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  handleChange = async event => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;
    await this.setState({
      [name]: value
    });
  };

  async handleSubmitForm(e) {
    e.preventDefault();
    //try to login the user
    const { email, password } = this.state;
    if (email && password) {
      this.props.login(email, password);
    }
  }

  getRedirectPath = () => {
    const { location } = this.props;
    return location.state && location.state.from
      ? location.state.from.pathname
      : "/";
  };

  render() {
    if (this.props.isAuthenticated) {
      const { location } = this.props;
      return (
        <div>
          <Redirect
            to={{
              pathname: this.getRedirectPath(),
              state: { from: this.props.location }
            }}
          />
        </div>
      );
    }
    if (!this.state.checkedSession) {
      return <Spinner />;
    }
    return (
      <div>
        <Login
          handleSubmitForm={this.handleSubmitForm}
          validateForm={this.validateForm}
          handleChange={this.handleChange}
          alert={this.props.alert}
          email={this.state.email}
          password={this.state.password}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { alert } = state;
  const { isAuthenticated } = state.auth;
  return { alert, isAuthenticated };
};

const mapDispatchToProps = {
  login: userActions.loginUser,
  getProfile: userActions.getProfile,
  clearAlerts: alertActions.clear
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginContainer);
