import React, { Component } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Tooltip,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faEdit } from "@fortawesome/free-regular-svg-icons";

import ParticipantDeleteModal from "./ParticipantDeleteModal";
import ParticipantEditModal from "./ParticipantEditModal";

class ParticipantActions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      editTooltipOpen: false,
      deleteModal: false,
      editModal: false,
    };
    this.toggle = this.toggle.bind(this);
    this.toggleEditHover = this.toggleEditHover.bind(this);
    this.toggleDeleteConfirm = this.toggleDeleteConfirm.bind(this);
    this.toggleEditModal = this.toggleEditModal.bind(this);
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }
  toggleEditHover() {
    this.setState({
      editTooltipOpen: !this.state.editTooltipOpen,
    });
  }
  toggleEditModal() {
    this.setState({
      editModal: !this.state.editModal,
    });
  }
  toggleDeleteConfirm() {
    this.setState({
      deleteModal: !this.state.deleteModal,
    });
  }

  render() {
    const { person } = this.props;

    return (
      <div className="ml-auto d-inline-flex align-items-center p-2">
        <FontAwesomeIcon
          size="lg"
          icon={faEdit}
          id={`edit-${person.participantId}`}
          className="text-primary m-1 d-none d-sm-inline"
          onClick={this.toggleEditModal}
        />
        <Tooltip
          placement="top"
          isOpen={this.state.editTooltipOpen}
          toggle={this.toggleEditHover}
          target={`edit-${person.participantId}`}
        >
          {"Edit"}
        </Tooltip>{" "}
        <Dropdown
          size="sm"
          isOpen={this.state.dropdownOpen}
          toggle={this.toggle}
        >
          <DropdownToggle outline>
            <FontAwesomeIcon size="1x" icon={faBars} />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem
              className="d-block d-md-none text-primary"
              onClick={this.toggleEditModal}
            >
              Edit
            </DropdownItem>
            <DropdownItem>
              <a href={"mailto:" + person.email} target="_blank">
                Send Email
              </a>
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem
              className="text-danger"
              onClick={this.toggleDeleteConfirm}
            >
              Delete
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <ParticipantDeleteModal
          person={person}
          isOpen={this.state.deleteModal}
          toggle={this.toggleDeleteConfirm}
        />
        <ParticipantEditModal
          person={person}
          isOpen={this.state.editModal}
          toggle={this.toggleEditModal}
        />
      </div>
    );
  }
}

export default ParticipantActions;
