import { GETPARTICIPANTS_SUCCESS } from "../actions";

function participantsReducer(participants = {}, action) {
  switch (action.type) {
    case GETPARTICIPANTS_SUCCESS: {
      return { [action.groupId]: action.participants };
    }

    default:
      return participants;
  }
}

export default participantsReducer;
