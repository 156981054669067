const settings = {
  LIST_TEXT: {
    attending: "Attending",
    noResponse: "No Response",
    notInvited: "Not Invited",
    notAttending: "Not Attending",
  },
  GROUP_TEXT: ["Primary Group", "Secondary Group"], //must correspond to number of groups
  INACTIVE_TEXT: "Inactive",
};

export default settings;
