import React from "react";

const EventAttendanceSummary = ({ targetParticipants, nextEventResponses }) => {
  const openSpots = targetParticipants - nextEventResponses.attending.length;
  let attendanceSummary;
  if (openSpots <= 0) {
    attendanceSummary = "All set, the event is full!";
  } else if (openSpots === 1) {
    attendanceSummary = "Still need 1 person";
  } else {
    attendanceSummary = `Still need ${openSpots} people`;
  }
  return <h3 className="p-2 p-sm-4 text-center">{attendanceSummary}</h3>;
};

export default EventAttendanceSummary;
