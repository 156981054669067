import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import GAListener from "./components/GAListener";
import { Provider } from "react-redux";

import store from "./store/store";
import App from "./containers/App";
import Response from "./components/Response/Response";
import Login from "./components/Login/LoginContainer";
import PrivateRoute from "./components/PrivateRoute";

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <div>
        <GAListener trackingId="UA-136688185-2">
          <Switch>
            <Route exact path="/response/:id/:response?" component={Response} />
            <Route exact path="/login" component={Login} />
            <PrivateRoute path="/" component={App} />
          </Switch>
        </GAListener>
      </div>
    </BrowserRouter>
  </Provider>,
  document.querySelector("#root")
);
