import AuthService from "../components/AuthService";
import { alertActions } from "./";

const Auth = new AuthService();

export const LOGIN_REQUEST = "USERS_LOGIN_REQUEST";
export const LOGIN_SUCCESS = "USERS_LOGIN_SUCCESS";
export const LOGIN_FAILURE = "USERS_LOGIN_FAILURE";
export const GETPROFILE_REQUEST = "USERS_GETPROFILE_REQUEST";
export const GETPROFILE_SUCCESS = "USERS_GETPROFILE_SUCCESS";
export const GETPROFILE_FAILURE = "USERS_GETPROFILE_FAILURE";
export const LOGOUT = "USERS_LOGOUT";

export const userActions = {
  loginUser,
  logoutUser,
  getProfile
};

function loginUser(email, password) {
  return async dispatch => {
    dispatch(request({ email }));

    try {
      await Auth.login(email, password);
      if (Auth.loggedIn()) {
        let user = await Auth.getProfile();
        dispatch(success(user));
      }
    } catch (e) {
      console.error(e);
      dispatch(failure(e.toString()));
      dispatch(alertActions.error(e.toString()));
    }
  };

  function request(email) {
    return { type: LOGIN_REQUEST, email };
  }
  function success(user) {
    return { type: LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: LOGIN_FAILURE, error };
  }
}

function getProfile() {
  return async dispatch => {
    dispatch(request());
    try {
      if (Auth.loggedIn()) {
        let user = await Auth.getProfile();
        dispatch(success(user));
      } else {
        const msg = "User not logged in";
        dispatch(failure(msg));
        dispatch(alertActions.error(msg));
      }
    } catch (e) {
      console.error(e);
      dispatch(failure(e.toString()));
      dispatch(alertActions.error(e.toString()));
    }
  };

  function request() {
    return { type: GETPROFILE_REQUEST };
  }
  function success(user) {
    return { type: GETPROFILE_SUCCESS, user };
  }
  function failure(error) {
    return { type: GETPROFILE_FAILURE, error };
  }
}

function logoutUser() {
  Auth.logout();
  return { type: LOGOUT };
}
