import React from "react";
import { Container, Col, Row } from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Spinner = (props) => {
  return (
    <Container>
      <Row>
        <Col
          style={{ marginTop: "28px" }}
          md={{ size: 8, offset: 2 }}
          lg={{ size: 6, offset: 3 }}
          xl={{ size: 6, offset: 3 }}
        >
          <div style={{ marginTop: "28px", textAlign: "center" }}>
            <FontAwesomeIcon icon="spinner" size="4x" spin />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Spinner;
