import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, ModalHeader, ModalFooter, ModalBody, Button } from "reactstrap";

import { participantActions } from "../../actions";

export class ParticipantDeleteModal extends Component {
  constructor(props) {
    super(props);
    this.deleteParticipantAndToggle = this.deleteParticipantAndToggle.bind(
      this
    );
    this.setInactiveAndToggle = this.setInactiveAndToggle.bind(this);
  }

  deleteParticipantAndToggle(participantId, groupId) {
    this.props.deleteParticipant(participantId, groupId);
    this.props.toggle();
  }
  setInactiveAndToggle(participantId) {
    this.props.changeParticipantStatus(participantId, false);
    this.props.toggle();
  }

  render() {
    const { person, isOpen, toggle } = this.props;
    let modalBody;

    if (person.active) {
      modalBody = (
        <ModalBody>
          Are you sure you want to delete {person.firstName} {person.lastName}?
          If you don't want to permanently delete the person you can mark them
          as <b>Inactive</b> instead so that they no longer receive invitations.
        </ModalBody>
      );
    } else {
      modalBody = (
        <ModalBody>
          Are you sure you want to delete {person.firstName} {person.lastName}?
        </ModalBody>
      );
    }

    return (
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          Delete {person.firstName} {person.lastName}?
        </ModalHeader>
        {modalBody}
        <ModalFooter>
          <Button
            color="danger"
            onClick={() =>
              this.deleteParticipantAndToggle(
                person.participantId,
                person.groupId
              )
            }
            className="mr-auto"
          >
            Delete
          </Button>
          {person.active ? (
            <Button
              color="primary"
              onClick={() => this.setInactiveAndToggle(person.participantId)}
            >
              Inactive
            </Button>
          ) : (
            ""
          )}{" "}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapDispatchToProps = {
  deleteParticipant: participantActions.deleteParticipant,
  changeParticipantStatus: participantActions.changeParticipantStatus,
};

export default connect(null, mapDispatchToProps)(ParticipantDeleteModal);
