import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { Container } from "reactstrap";

import Header from "../components/Header/Header";
import DashboardContainer from "./DashboardContainer";
import { userActions, groupActions, appActions } from "../actions";
import "./App.css";

class App extends Component {
  constructor(props) {
    super(props);
    this.handleLogout = this.handleLogout.bind(this);
  }

  componentDidMount() {
    if (isEmpty(this.props.groups)) {
      this.props.getGroupsWithEvents();
    }
  }

  handleLogout() {
    this.props.logoutUser();
    this.props.history.replace("/login");
  }

  render() {
    let activeGroupId = this.props.selectedGroup;
    let activeGroupName;
    if (this.props.selectedGroup && !isEmpty(this.props.groups)) {
      const group = this.props.groups.find(
        (g) => g._id === this.props.selectedGroup
      );
      "name" in group
        ? (activeGroupName = group.name)
        : (activeGroupName = null);
    }
    return (
      <Container className="bg-white px-0 pt-0 pb-0 pb-sm-3">
        <Header
          logout={this.handleLogout}
          user={this.props.user}
          activeGroupId={activeGroupId}
          activeGroupName={activeGroupName}
        />
        <DashboardContainer />
        <Redirect exact from="/" to="/home" />
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    isAuthenticated: state.auth.isAuthenticated,
    groups: state.groups,
    selectedGroup: state.app.selectedGroup,
  };
};

const mapDispatchToProps = {
  logoutUser: userActions.logoutUser,
  getGroupsWithEvents: groupActions.getGroupsWithEvents,
  setSelectedGroup: appActions.setSelectedGroup,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

export { App };
