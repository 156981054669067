import React, { Component } from "react";
import { connect } from "react-redux";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

import { participantActions } from "../../actions";
//import EditParticipantForm from "../EditParticipantForm/EditParticipantForm";

export class ParticipantEditModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      active: "",
      groupLevel: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    //this.setInactiveAndToggle = this.setInactiveAndToggle.bind(this);
  }

  componentDidMount() {
    if (this.props.person) {
      const p = this.props.person;
      this.setState({
        firstName: p.firstName,
        lastName: p.lastName,
        email: p.email,
        active: p.active,
        groupLevel: p.groupLevel,
      });
    }
  }

  handleChange(e) {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  }

  handleCheckboxChange(e) {
    e.preventDefault();
    this.setState({ active: !this.state.active });
  }

  handleSubmit() {
    //e.preventDefault();
    this.props.updateParticipant(
      this.state,
      this.props.person.participantId,
      this.props.person.groupId
    );
    this.props.toggle();
  }

  render() {
    const { person, isOpen, toggle } = this.props;

    return (
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          Edit {person.firstName} {person.lastName}?
        </ModalHeader>
        <AvForm onValidSubmit={this.handleSubmit}>
          <ModalBody>
            <Row form>
              <Col md={6}>
                <AvField
                  type="text"
                  name="firstName"
                  id="firstnameField"
                  placeholder="Lionel"
                  defaultValue={person.firstName}
                  onChange={this.handleChange}
                  label="First Name"
                  required
                  errorMessage="First name is required"
                />
              </Col>
              <Col md={6}>
                <AvField
                  type="text"
                  name="lastName"
                  id="lastnameField"
                  placeholder="Messi"
                  defaultValue={person.lastName}
                  onChange={this.handleChange}
                  label="Last Name"
                  required
                  errorMessage="Last name is required"
                />
              </Col>
            </Row>
            <AvField
              type="email"
              name="email"
              id="emailField"
              placeholder="lionel.messi@fcbarcelona.com"
              defaultValue={person.email}
              onChange={this.handleChange}
              label="Email"
              required
              errorMessage="Valid email is required"
            />
            <Row>
              <Col>
                <FormGroup>
                  <Label for="groupLevelSelect">Invite Priority</Label>
                  <Input
                    type="select"
                    name="groupLevel"
                    id="groupLevelSelect"
                    defaultValue={person.groupLevel}
                    onChange={this.handleChange}
                  >
                    <option value={0}>Primary</option>
                    <option value={1}>Secondary</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      name="active"
                      bsSize="lg"
                      defaultChecked={!person.active}
                      onChange={this.handleCheckboxChange}
                    />{" "}
                    Inactive
                  </Label>
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" className="mr-auto">
              Save Changes
            </Button>
            <Button color="secondary" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    );
  }
}

const mapDispatchToProps = {
  updateParticipant: participantActions.updateParticipant,
};

export default connect(null, mapDispatchToProps)(ParticipantEditModal);
