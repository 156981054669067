import React from "react";
import { Container, Row, Col } from "reactstrap";

import ActionMenu from "../components/ActionMenu/ActionMenu";
import ContentArea from "./ContentArea";

const DashboardContainer = (props) => {
  return (
    <Container className="pr-0 pl-0">
      <Row>
        <Col>
          <ActionMenu />
        </Col>
      </Row>
      <Row>
        <Col>
          <ContentArea />
        </Col>
      </Row>
    </Container>
  );
};

export default DashboardContainer;
