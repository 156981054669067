import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

import ParticipantList from "../ParticipantList/ParticipantList";
import settings from "../../config/settings";
import { groupParticipantsByLevel } from "../sharedFunctions";
import "./Participants.css";

const Participants = ({ group, participants, groupLevel }) => {
  const groupId = group._id;
  const groupedParticipants = groupParticipantsByLevel(
    participants[groupId],
    settings.GROUP_TEXT
  );
  const lists = settings.GROUP_TEXT.concat([settings.INACTIVE_TEXT]);

  return (
    <React.Fragment>
      <Row>
        <Col sm={{ size: 8, offset: 2 }}>
          <h3 className="p-2 p-sm-4 text-center">Members by Group</h3>
        </Col>
      </Row>
      <Row>
        <Col className="px-0 px-sm-3" sm={{ size: 8, offset: 2 }}>
          {lists.map((levelName, index) => {
            return (
              <ParticipantList
                key={levelName}
                listType="member"
                participants={groupedParticipants[index]}
                titleText={levelName}
              />
            );
          })}
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Participants;
