import React from "react";
import { Nav, NavItem } from "reactstrap";
import { NavLink } from "react-router-dom";

import "./ActionMenu.css";

const ActionMenu = (props) => {
  return (
    <Nav
      className="justify-content-center bg-dark align-items-center"
      style={{ height: "60px" }}
    >
      <NavItem>
        <NavLink to="/home" className="nav-link text-white">
          Next Event
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink to="/participants" className="nav-link text-white">
          Members
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink to="/events" className="nav-link text-white">
          Events
        </NavLink>
      </NavItem>
    </Nav>
  );
};

export default ActionMenu;
