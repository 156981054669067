import { SET_SELECTEDGROUP } from "../actions";

function appReducer(app = { selectedGroup: null }, action) {
  switch (action.type) {
    case SET_SELECTEDGROUP: {
      const { groupId } = action;
      return { selectedGroup: groupId };
    }

    default:
      return app;
  }
}

export default appReducer;
