import React, { Component } from "react";
import {
  Col,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Collapse
} from "reactstrap";
import axios from "axios";
import AuthService from "../AuthService";

class EventParticipantModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      groupLevel: 1,
      showModal: false,
      checked: false,
      showFields: false,
      formValid: false,
      emailValid: false
    };
    this.toggleFields = this.toggleFields.bind(this);
    this.handleUserInput = this.handleUserInput.bind(this);
    this.validateField = this.validateField.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.submitResponseAndToggle = this.submitResponseAndToggle.bind(this);
    this.Auth = new AuthService();
  }

  // componentDidMount() {
  //   modalRoot.appendChild(this.el);
  // }
  //
  // componentWillUnmount() {
  //   modalRoot.removeChild(this.el);
  // }

  toggleFields() {
    this.setState(
      {
        checked: !this.state.checked,
        showFields: !this.state.showFields
      },
      this.validateForm
    );
  }

  handleUserInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
  }

  validateField(fieldName, value) {
    //let fieldValidationErrors = this.state.formErrors;
    let emailValid = this.state.emailValid;

    switch (fieldName) {
      case "email":
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        //fieldValidationErrors.email = emailValid ? '' : ' is invalid';
        break;
      default:
        break;
    }
    this.setState({ emailValid: emailValid }, this.validateForm);
  }

  validateForm() {
    if (this.state.showFields) {
      // require email, group and active values
      this.setState({
        formValid:
          this.state.firstName && this.state.lastName && this.state.emailValid
      });
    } else {
      this.setState({ formValid: this.state.firstName && this.state.lastName });
    }
  }

  async submitResponseAndToggle() {
    // create participant and invite
    let person = null;
    try {
      const participant_res = await axios.post(
        `/api/participants`,
        {
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          email: this.state.email ? this.state.email : null,
          groupId: this.props.groupId,
          groupLevel: this.state.groupLevel
        },
        {
          headers: { Authorization: `Bearer ${this.Auth.getToken()}` }
        }
      );
      person = participant_res.data;
    } catch (err) {
      console.error(err.message);
    }
    // create invite
    if (person) {
      try {
        await axios.post(
          `/api/invites`,
          {
            participantId: person._id,
            participantFirstName: person.firstName,
            participantLastName: person.lastName,
            participantLevel: person.groupLevel,
            participantEmail: person.email ? person.email : null,
            eventId: this.props.nextEventId,
            groupId: this.props.groupId,
            groupName: this.props.groupName,
            response: 1
          },
          {
            headers: { Authorization: `Bearer ${this.Auth.getToken()}` }
          }
        );
      } catch (err) {
        console.error(err.message);
      }
    }
    this.props.toggle();
    this.setState({
      checked: false
    });
    //reload the page so that the new person appears in the list
    // const history = createBrowserHistory();
    // history.go(0);
    //this.props.submitResponse(response);
  }

  render() {
    const { open, toggle } = this.props;
    return (
      <Modal isOpen={open} toggle={toggle}>
        <ModalHeader toggle={toggle}>Add Someone New to the Event</ModalHeader>
        <ModalBody>
          <Form onSubmit={this.handleSubmit}>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="firstName">First Name</Label>
                  <Input
                    type="text"
                    name="firstName"
                    id="firstName"
                    placeholder="Lionel"
                    onChange={event => this.handleUserInput(event)}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="lastName">Last Name</Label>
                  <Input
                    type="text"
                    name="lastName"
                    id="lastName"
                    placeholder="Messi"
                    onChange={event => this.handleUserInput(event)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <FormGroup check>
              <Input
                type="checkbox"
                name="showFields"
                id="showFieldsCheckbox"
                checked={this.state.checked}
                onChange={() => this.toggleFields()}
              />
              <Label for="showFieldsCheckbox" check>
                Invite to Future Events
              </Label>
            </FormGroup>
            <br />
            <Collapse isOpen={this.state.showFields}>
              <FormGroup>
                <Label for="emailAddress" check>
                  Email
                </Label>
                <Input
                  type="email"
                  name="email"
                  id="emailAddress"
                  placeholder="lionel.messi@fcbarcelona.com"
                  onChange={event => this.handleUserInput(event)}
                />
              </FormGroup>
              <FormGroup>
                <Label for="selectGroup" check>
                  Invite Group
                </Label>
                <Input
                  type="select"
                  name="groupLevel"
                  id="selectGroup"
                  defaultValue={1}
                  onChange={event => this.handleUserInput(event)}
                >
                  <option value={0}>Primary</option>
                  <option value={1}>Secondary</option>
                </Input>
              </FormGroup>
            </Collapse>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => this.submitResponseAndToggle()}
            disabled={!this.state.formValid}
          >
            Add
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default EventParticipantModal;
