import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
//import { createLogger } from "redux-logger";
import rootReducer from "../reducers/reducers";
import { composeWithDevTools } from "redux-devtools-extension";

//const loggerMiddleware = createLogger();

let initialState = {
  auth: {
    isAuthenticated: false,
    isError: null,
  },
  //selectedGroup: null,
  groups: [],
  events: {},
  responses: {},
  participants: {},
  app: { selectedGroup: null },
};

// add loggerMiddleware to log redux actions:
//  composeWithDevTools(applyMiddleware(thunkMiddleware, loggerMiddleware))
export default createStore(
  rootReducer,
  initialState,
  composeWithDevTools(applyMiddleware(thunkMiddleware))
);
