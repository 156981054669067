import React from "react";
import { Container, Col, Row } from "reactstrap";
import { Link } from "react-router-dom";

import NextEvent from "../NextEvent/NextEvent";
import settings from "../../config/settings";
import "./Home.css";

const Home = (props) => {
  const { group, eventDetails, nextEventResponses } = props;

  //If no event details or responses, show No Event message
  const noEvent = (
    <React.Fragment>
      <Row>
        <Col sm={{ size: 6, offset: 3 }}>
          <h3 className="contentTitle">No Future Events</h3>
        </Col>
      </Row>
      <Row>
        <Col sm={{ size: 6, offset: 3 }}>
          <span>
            Uh oh, there’s nothing on the calendar for Group Name. Add an event
            and let’s get everyone back together soon!
          </span>
          <div className="link">
            <Link to="/events">Create a New Event</Link>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
  return (
    <React.Fragment>
      {!eventDetails || !nextEventResponses ? (
        noEvent
      ) : (
        <NextEvent
          targetParticipants={group.targetParticipants}
          eventDetails={eventDetails}
          nextEventResponses={nextEventResponses}
        />
      )}
    </React.Fragment>
  );
};

export default Home;
